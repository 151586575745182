<template>
  <!-- 新通证申购管理 -->
  <div>
    <!-- 顶部新增按钮 -->
    <div class="onBtn">
      <el-button
        type="success"
        size="mini"
        @click="addNewProfile"
        style="margin-left: auto"
        >新增</el-button
      >
    </div>

    <!-- 中间表格区 -->
    <el-table border>
      <el-table-column label="序号" width="80" align="center"></el-table-column>
      <el-table-column label="标题" width="80" align="center"></el-table-column>
      <el-table-column
        label="数字通证"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        label="公司名"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        label="申购起始时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        label="申购结束时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        label="网上申购数量"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        label="发行总量"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="采购价格"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        label="采购数量"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        label="中签公告日"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        label="申购扣款时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        label="扣款汇总账号"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="状态"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="180"
        align="center"
      ></el-table-column>
    </el-table>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 123,
      total: 23,
      currentPage4: 1,
    };
  },
  methods: {
    //   点击跳转修改页面
    addNewProfile() {
      console.log("新增");
      this.$router.push("newCardPurchase");
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
